import { useCallback, useContext, useEffect, useState } from "react";
import { FormError } from "../../../components/elements/FormsElements/FormError";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  FormErrors,
  FormModeState,
  getOptionFromKeyValuePairs,
  mapToOptions,
  useUpdateFindingInPlace,
} from "../../../shared/formUtils";
import { AdminFindingEdit, Attachment, Finding } from "../../../types/Finding";
import { FindingDetailsBreadCrumbs } from "./FindingDetailsBreadCrumbs";
import { ThemeContext } from "styled-components";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { LabelRegular } from "../../../components/elements/typography/Typography";
import { TagsLine } from "../../../components/composed/tagsLine/TagsLine";
import { SeparatorVertical } from "../../../components/elements/separators/SeparatorVertical";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { cweMap } from "../../insights/cwePieChart/cweMap";
import { TopBoxes } from "./TopBoxes";
import { MiddleBox } from "./MiddleBox";
import {
  useApiFindingAttachments,
  useApiSingleFinding,
} from "../../../hooks/queries/findingContext";
import { BottomBoxEdit } from "./BottomBoxEdit";
import { useParams } from "react-router";
import { useApiMe } from "../../../hooks/queries/meContext";
import { Switch } from "../../../components/elements/switch/Switch";
import { FindingAttachments } from "../../admin/research/sub-components/FindingAttachments";
import { DifferentCustomerWarning } from "./DifferentCustomerWarning";

// WHEN ADDING TEXT FIELDS TO FORM , ADD THE FIELD KEY HERE
export const isFindingHasUnsavedChanges = (
  cachedFinding: AdminFindingEdit,
  finding: Finding
): boolean => {
  if (!cachedFinding || !finding) return false;
  const fields: Array<keyof Finding & keyof AdminFindingEdit> = [
    "title",
    "summary",
    "cvss_score",
    "cvss_vector",
    "description_wasp",
    "attack_details_wasp",
    "mitigation_wasp",
  ];
  return fields.some((field) => cachedFinding[field] !== finding[field]);
};

type Props = {
  finding?: Finding;
  onClose: () => void;
};

export const FindingsDetailsEdit = (props: Props) => {
  const { onClose } = props;
  const theme = useContext(ThemeContext);

  const { data: me } = useApiMe();

  const { id: findingIdStr } = useParams();
  const findingId = findingIdStr ? parseInt(`${findingIdStr}`) : undefined;
  const { data: fetchedFinding, refetch: refetchFinding } = useApiSingleFinding(
    findingId,
    !!findingId
  );

  // THE ORIGINAL FINDING WE UPDATE
  const [updateFinding, setUpdateFinding] = useState<Finding | undefined>(
    fetchedFinding
  );

  // EDITED FINDING DATA (aka formValues)
  const [editableUpdateFindingData, setEditableUpdateFinding] =
    useState<AdminFindingEdit | null>(fetchedFinding as AdminFindingEdit);

  // HANDLE LOCAL STORAGE
  const storageKey = `findingEdit${findingId}`;
  const cachedFindingStr = localStorage.getItem(storageKey);

  const cachedFindingData = !!cachedFindingStr?.length
    ? JSON.parse(cachedFindingStr)
    : null;

  const setCachedFindingData = useCallback(
    async (data: AdminFindingEdit) => {
      localStorage.setItem(
        storageKey,
        JSON.stringify({ ...editableUpdateFindingData, ...data })
      );
    },
    [storageKey, editableUpdateFindingData]
  );

  const deleteCachedFindingData = () => localStorage.removeItem(storageKey);

  useEffect(() => {
    if (!fetchedFinding) return;
    // keep the original finding updated on every render
    if (!updateFinding || fetchedFinding !== updateFinding) {
      console.log("UPDATING ORIGINAL FINDING STATE");
      setUpdateFinding(fetchedFinding);
    }
    if (
      cachedFindingData &&
      isFindingHasUnsavedChanges(cachedFindingData, fetchedFinding)
    ) {
      //We have cached data, use it for the editable copy
      if (
        !editableUpdateFindingData ||
        isFindingHasUnsavedChanges(
          cachedFindingData,
          editableUpdateFindingData as Finding
        )
      ) {
        console.log("UPDATING EDITABLE FINDING STATE - CACHED DATA");
        setEditableUpdateFinding({ ...fetchedFinding, ...cachedFindingData });
      }
    } else {
      console.log("UPDATING EDITABLE FINDING STATE - ORIGINAL DATA");
      setEditableUpdateFinding(fetchedFinding);
      console.log("UPDATING CACHED FINDING STATE - ORIGINAL DATA DATA");
      setCachedFindingData(fetchedFinding);
    }
  }, [
    fetchedFinding,
    updateFinding,
    editableUpdateFindingData,
    cachedFindingData,
    setCachedFindingData,
  ]);

  const isUnsavedData =
    (fetchedFinding as AdminFindingEdit) !== cachedFindingData;

  const handleClose = () => {
    if (!isUnsavedData) deleteCachedFindingData();
    onClose();
  };

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [findingAttachments, setAttachments] = useState<Attachment[]>([]);

  const { data: currentAttachmentsNames } = useApiFindingAttachments(findingId);

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    findingId || 0,
    setUpdateFinding
  );

  return (
    <Flex column gap="16px">
      {me?.customer.id !== updateFinding?.customer && (
        <DifferentCustomerWarning customerId={updateFinding?.customer} />
      )}
      <Flex align="center" w100>
        <FindingDetailsBreadCrumbs finding={fetchedFinding} backUrl="" />
      </Flex>
      {/* TITLE */}
      <Flex column>
        <Flex align="center">
          <InputText
            placeholder="Enter finding name"
            saveChangesMode
            isRequired
            variant="header"
            width="100%"
            queryStatus={changedField === "title" ? queryStatus : undefined}
            disabled={queryStatus && queryStatus !== "idle"}
            dataTestId="title-input"
            initValue={updateFinding?.title}
            value={editableUpdateFindingData?.title || ""}
            onChange={(e) => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                title: e.target.value,
              }));
              setCachedFindingData({
                title: e.target.value,
              });

              let errors = { ...formErrors };
              errors.title = e.target.value === "" ? ["Title is required"] : [];
              setFormErrors(errors);
            }}
            onKeyDown={() =>
              updateInPlace({ title: editableUpdateFindingData?.title })
            }
            onSave={() =>
              updateInPlace({ title: editableUpdateFindingData?.title })
            }
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                title: updateFinding?.title,
              }));
              setCachedFindingData({ title: updateFinding?.title });
            }}
            isError={!!formErrors?.title?.length}
          />
          <Flex style={{ width: "50%" }}>
            <LinkButton
              size="medium"
              label="Close Editor"
              iconName="cancel"
              onClick={handleClose}
            />
          </Flex>
        </Flex>
        {formErrors?.title?.map((err) => <FormError errorMessage={err} />)}
      </Flex>
      {/* IS_PENDING && IS_FALSE_POSITIVE */}
      <Flex
        justify="around"
        align="center"
        padding="8px"
        style={{
          backgroundColor: theme.blue50,
          borderRadius: "8px",
          border: `1px solid ${theme.primary}`,
        }}
      >
        {(!me?.customer.is_multi_tenant || me?.is_superuser) && (
          <Flex gap="8px" align="center">
            <LabelRegular>Show in WASP</LabelRegular>
            <Switch
              checked={!editableUpdateFindingData?.is_pending}
              onChange={(checked) => {
                updateInPlace({ is_pending: !checked });
                setCachedFindingData &&
                  setCachedFindingData({ is_pending: !checked });
              }}
              disabled={queryStatus !== "idle"}
              inProgress={
                changedField === "is_pending" && queryStatus !== "idle"
              }
            />
          </Flex>
        )}
        <Flex gap="8px" align="center">
          <LabelRegular>False Positive</LabelRegular>
          <Switch
            checked={!!editableUpdateFindingData?.is_false_positive}
            onChange={(checked) => {
              updateInPlace({ is_false_positive: checked });
              setCachedFindingData &&
                setCachedFindingData({ is_false_positive: checked });
            }}
            disabled={queryStatus !== "idle"}
            inProgress={
              changedField === "is_false_positive" && queryStatus !== "idle"
            }
          />
        </Flex>
      </Flex>
      <Flex flexWrap align="center" gap="16px">
        {/* CVE */}
        <Flex align="center" gap="16px">
          <LabelRegular>CVEs</LabelRegular>
          <TagsLine
            tagStyle={{ backgroundColor: theme.blue100, color: theme.primary }}
            isEditable
            isDisabled={queryStatus !== "idle"}
            selectedTags={editableUpdateFindingData?.cve_ids || []}
            onTagAdd={(tagCve: string) => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                cve_ids: prev?.cve_ids ? [...prev.cve_ids, tagCve] : [tagCve],
              }));
              updateInPlace({
                cve_ids: editableUpdateFindingData?.cve_ids
                  ? [...editableUpdateFindingData.cve_ids, tagCve]
                  : [tagCve],
              });
              setCachedFindingData({
                cve_ids: editableUpdateFindingData?.cve_ids
                  ? [...editableUpdateFindingData.cve_ids, tagCve]
                  : [tagCve],
              });
            }}
            onTagRemove={(tagCve: string) => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                cve_ids: prev?.cve_ids
                  ? prev.cve_ids.filter((cve) => cve !== tagCve)
                  : [],
              }));
              updateInPlace({
                cve_ids: editableUpdateFindingData?.cve_ids
                  ? editableUpdateFindingData.cve_ids.filter(
                      (cve) => cve !== tagCve
                    )
                  : [],
              });
              setCachedFindingData({
                cve_ids: editableUpdateFindingData?.cve_ids
                  ? editableUpdateFindingData.cve_ids.filter(
                      (cve) => cve !== tagCve
                    )
                  : [],
              });
            }}
          />
        </Flex>

        <SeparatorVertical height="32px" />

        {/* CWE */}
        <Flex align="center" gap="8px">
          <LabelRegular>CWE ID</LabelRegular>
          <Dropdown
            placeholder="Select CWE id"
            searchable
            options={mapToOptions(cweMap)}
            variant="border"
            size="medium"
            value={
              !!editableUpdateFindingData?.cwe_id
                ? getOptionFromKeyValuePairs(
                    cweMap,
                    editableUpdateFindingData.cwe_id
                  )
                : null
            }
            onChange={(opt) => {
              if (opt?.value) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  cwe_id: Number(opt.value),
                }));
                updateInPlace({ cwe_id: Number(opt.value) });
                setCachedFindingData({ cwe_id: Number(opt.value) });
              }
            }}
            disabled={queryStatus !== "idle"}
            queryStatus={changedField === "cwe_id" ? queryStatus : undefined}
          />
        </Flex>

        <SeparatorVertical height="32px" />

        {/* LABELS */}
        <Flex align="center" gap="8px">
          <LabelRegular>Labels</LabelRegular>
          <TagsLine
            isEditable
            isDisabled={queryStatus !== "idle"}
            selectedTags={editableUpdateFindingData?.labels || []}
            onTagAdd={(tagLabel: string) => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                labels: editableUpdateFindingData?.labels
                  ? [...editableUpdateFindingData?.labels, tagLabel]
                  : [tagLabel],
              }));
              updateInPlace({
                labels: editableUpdateFindingData?.labels
                  ? [...editableUpdateFindingData?.labels, tagLabel]
                  : [tagLabel],
              });
              setCachedFindingData({
                labels: editableUpdateFindingData?.labels
                  ? [...editableUpdateFindingData?.labels, tagLabel]
                  : [tagLabel],
              });
            }}
            onTagRemove={(tagLabel: string) => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                labels: editableUpdateFindingData?.labels
                  ? editableUpdateFindingData?.labels.filter(
                      (label) => label !== tagLabel
                    )
                  : [],
              }));
              updateInPlace({
                labels: editableUpdateFindingData?.labels
                  ? editableUpdateFindingData?.labels.filter(
                      (label) => label !== tagLabel
                    )
                  : [],
              });
              setCachedFindingData({
                labels: editableUpdateFindingData?.labels
                  ? editableUpdateFindingData?.labels.filter(
                      (label) => label !== tagLabel
                    )
                  : [],
              });
            }}
          />
        </Flex>
      </Flex>
      {/* DATE OPENED , SLA, STATUS, SEVERITY  */}
      <TopBoxes
        finding={fetchedFinding}
        isEditable
        setUpdateFinding={setUpdateFinding}
        editableUpdateFindingData={editableUpdateFindingData}
        setEditableUpdateFinding={setEditableUpdateFinding}
        setCachedFindingData={setCachedFindingData}
      />
      {/* ASSIGNEE, IMPACT, EXPLOITABILITY, CVSS, PROJECT, ISSUED BY, LAST SEEN, SCANNER */}
      <MiddleBox
        finding={fetchedFinding}
        isEditable
        editableUpdateFindingData={editableUpdateFindingData}
        setUpdateFinding={setUpdateFinding}
        setEditableUpdateFinding={setEditableUpdateFinding}
        cachedFindingData={cachedFindingData}
        setCachedFindingData={setCachedFindingData}
      />

      {/* ATTACHMENTS */}
      <FindingAttachments
        findingAttachmentsToCreate={findingAttachments}
        setAttachmentsToCreate={setAttachments}
        currentAttachmentsNames={currentAttachmentsNames}
        findingId={fetchedFinding?.id}
        formMode={FormModeState.Update}
      />

      {/* DESCRIPTION , ATTACK DETAILS , MITIGATION */}
      <BottomBoxEdit
        finding={fetchedFinding}
        editableUpdateFindingData={editableUpdateFindingData}
        setEditableUpdateFinding={setEditableUpdateFinding}
        refetchFinding={refetchFinding}
        setCachedFindingData={setCachedFindingData}
      />
    </Flex>
  );
};

import { TabButtonWithCounter } from "../../components/elements/button/tab/TabButtonWithCounter";
import { useApiFindingsCounts } from "../../hooks/queries/findingContext";
import { tabsFiltersMap } from "./Findings";

type Props = {
  onClick: (tabKey: string) => void;
  selectedTab: string;
  label: string;
  tabKey: string;
};

export const FindingsQuickFilter = (props: Props) => {
  const { tabKey, selectedTab, label, onClick } = props;

  const filters = tabsFiltersMap[tabKey];

  const { data: findingsCounts } = useApiFindingsCounts(filters);

  return (
    <TabButtonWithCounter
      label={label}
      onClick={onClick}
      count={findingsCounts?.total}
      tabKey={tabKey}
      selectedTab={selectedTab}
    />
  );
};

import { useContext, useMemo, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import {
  BodyRegular,
  HeaderSecondary,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { useApiAssetsPaging } from "../../../hooks/queries/assetsContext";
import { Finding } from "../../../types/Finding";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Asset } from "../../../types/Asset";
import { Loading } from "../../../components/elements/loading/Loading";

export const AffectedAssetsPane = ({
  finding,
  onClose,
  onClickAsset,
}: {
  finding: Finding;
  onClose: () => void;
  onClickAsset?: (assetId: number) => void;
}) => {
  const theme = useContext(ThemeContext);

  const [assetsSearchWord, setAssetsSearchWord] = useState("");

  const {
    data: assets,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useApiAssetsPaging({ id: finding.affected_assets });

  const pagedAssets = useMemo(
    () => assets?.pages?.map((page) => page?.results || []).flat() || [],
    [assets]
  );

  const filteredAssets =
    pagedAssets?.filter((a) => a.name.includes(assetsSearchWord)) || [];

  return (
    <RightPane onClose={onClose} width="992px">
      <Flex
        column
        gap="32px"
        w100
        h100
        style={{ padding: "32px", overflowY: "clip" }}
      >
        <Flex column gap="16px">
          <Flex align="center" gap="16px" w100 justify="between">
            <Flex gap="8px">
              <IconButton
                iconName="chevronLeft"
                color={theme.primary}
                size="medium"
                onClick={onClose}
              />
              <Flex column>
                <LabelRegular>{finding.title}</LabelRegular>
                <HeaderSecondary
                  className="text-truncate"
                  style={{ maxWidth: "300px", textTransform: "none" }}
                >
                  Affected Assets
                </HeaderSecondary>
              </Flex>
            </Flex>
            <Flex>
              <InputText
                dataTestId="product-search-bar"
                onChange={(e) => setAssetsSearchWord(e.target.value)}
                placeholder="Search"
                iconName="search"
                width={"100%"}
              />
            </Flex>
          </Flex>
          <SeparatorHorizontal />
          <MyListComponent
            fetchNextPage={fetchNextPage}
            filteredAssets={filteredAssets}
            hasNextPage={!!hasNextPage}
            onClose={onClose}
            onClickAsset={onClickAsset}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Flex>
      </Flex>
    </RightPane>
  );
};

type Props = {
  filteredAssets: Asset[];
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  onClickAsset?: (assetId: number) => void;
  onClose: () => void;
};

export const MyListComponent = ({
  filteredAssets,
  hasNextPage,
  fetchNextPage,
  onClickAsset,
  onClose,
  isFetchingNextPage,
}: Props) => {
  const observer = useRef(null);
  useInfiniteScroll(observer, !!hasNextPage, fetchNextPage);

  return (
    <Flex
      column
      gap="16px"
      style={{
        height: "calc(100vh - 150px)",
        overflowY: "scroll",
      }}
    >
      {filteredAssets?.map((asset, idx) => (
        <Flex column gap="8px" key={idx}>
          <Flex align="center" justify="between" w100>
            <BodyRegular className="text-truncate">
              {idx + 1} {". "}
              {asset.name}
            </BodyRegular>
            <Flex>
              <IconButton
                iconName="chevronRight"
                size="small"
                onClick={() => {
                  onClickAsset && onClickAsset(asset.id);
                  onClose();
                }}
                dataTestId={`open-asset-${asset.name}`}
              />
            </Flex>
          </Flex>
          <SeparatorHorizontal />
          {hasNextPage && idx === filteredAssets.length - 1 && (
            <Flex ref={observer} style={{ color: "transparent" }}>
              OBS
            </Flex>
          )}
        </Flex>
      ))}
      {isFetchingNextPage ? <Loading /> : null}
    </Flex>
  );
};
